import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';

function App() {
  const [name, setName] = useState("");
  const [askedWho, setAskedWho] = useState(false);
  const [wrongPerson, setWrongPerson] = useState(false);
  const [noButtonClickCount, setNoButtonClickCount] = useState(0);
  const [noButtonText, setNoButtonText] = useState("No");
  const [yesButtonClicked, setYesButtonClicked] = useState(false);

  useEffect(() => {
    // Extracting the name from the URL path
    const path = window.location.pathname;
    const parts = path.split('/');
    const decodedName = decodeURIComponent(parts[1] || ""); // Decoding URL-encoded name
    setName(decodedName);
    if (decodedName) {
      setAskedWho(true); // Assuming user is already on the page with the name in the URL
    }
  }, []); // Empty dependency array ensures this effect runs only once

  const CheckIfName = () => {
    return ( 
      <div className="centered">
        <h1>Is your name {name.replace('+', ' ')}?</h1>
        <button className="btn" onClick={() => setAskedWho(false)}>Yes</button>
        <button className="btn" onClick={() => {
            setWrongPerson(true);
            setAskedWho(false);
          }
        }>No</button>
      </div>
    )
  }

  const goAway = () => {
    return (
      <div className="centered">
        <h1>Go away, this is for {name.replace('+', ' ')}...</h1>
      </div>
    )
  }

  const theBigQuestion = () => {
    const handleNoButtonClick = () => {
      setNoButtonClickCount(noButtonClickCount + 1);
      setNoButtonText(
        "Are you" + Array(noButtonClickCount + 1).fill(" sure").join('') + "?"
      )
    };

    const handleYesButtonClick = () => {
      setYesButtonClicked(true);
    };

    return (
      <div className='centered'>
        <h1>Will you be my valentine?</h1>
        <div className="theButtons">
          <button className="yesBtn" onClick={handleYesButtonClick}>Yes</button>
          <button className="noBtn" onClick={handleNoButtonClick}>{noButtonText}</button>
        </div>
      </div>
    )
  }

  const CongratsMessage = () => {
    return (
      <div className="centered">
        <h1>Ohhh myyyy gosh!!!</h1>
        <h2>Woahhh can't believe you said yesss.......</h2>
        <h2>{name.split('+')[0]} + Ryan 🤤</h2>
        <h2>Love Ryan ❤️</h2>
        <img src='https://upload.wikimedia.org/wikipedia/commons/f/f1/Heart_corazón.svg' alt='heart'/>
      </div>
    )
  }

  return (
    <div className="App">
      {name && !askedWho ? (
        <div>
          {wrongPerson ? goAway() : (
            <div>{yesButtonClicked ? <CongratsMessage /> : theBigQuestion()}</div>
          )}
        </div>
      ) : (
        <div>
          
          <CheckIfName />
        </div>
      )}
    </div>
  );
}

export default App;
